import React from "react";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";

let tags = [
  { name: "الرئيسية", link: "/" },
  { name: "الخدمات", link: "/services" },
  { name: "البرامج السياحية", link: "/tourismProgrammes" },
  { name: "من نحن ", link: "/aboutUs" },
  { name: "المدونة", link: "/blogs" },
  { name: "تواصل معنا", link: "/contactUs" },
  { name: "الأسئلة المتكررة", link: "/commonQuestions" },
  { name: "سياسة الخصوصية", link: "/privacyPolicy" },
];

let services = [
  { name: "سيارة مع سائق", link: "/services" },
  { name: "حجوزات طيران", link: "/services" },
  { name: "فنادق و منتجعات", link: "/services" },
  { name: "البرامج السياحية", link: "/services" },
  { name: "الرحلات السياحية اليومية", link: "/services" },
];

function Footer() {
  return (
    <footer className="text-center text-lg-start bg-light text-muted fs-7 py-3">
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3 ">
            <div className="col-md-6  col-xl-3 mb-4 text-end p-xl-0 ps-3">
              <h6 className="text-uppercase fw-bold mb-2">
                <img
                  width={120}
                  src="https://i.ibb.co/djsQZN2/Screenshot-2-removebg-preview.png"
                  alt="logo"
                />
              </h6>
              <p>
                هي شركة متخصصة في السياحة وتنظيم الرحلات والانشطة السياحية في
                تركيا وخصوصا الشمال التركي, حيث راكمنا طيلة سنوات من العمل في
                هذا الميدان خبرة كبيرة في تنظيم الرحلات داخل تركيا ولكل الجنسيات
                وحسب ميزانية ورغبة عملائنا.
              </p>
            </div>

            <div className="col-md-6 col-xl-2  mb-4 text-end">
              <h5 className="text-uppercase fw-bold mb-3 text-black">
                روابط مهمة
              </h5>
              <div className="column">
                {tags.map((item) => {
                  return (
                    <Link
                      className="text-decoration-none text-reset my-2 d-block"
                      key={item.link}
                      to={item.link}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="col-md-6 col-xl-2 mb-4 text-end">
              <h5 className="text-uppercase fw-bold mb-3 text-black">
                خدماتنا
              </h5>
              <div className="column">
                {services.map((item) => {
                  return (
                    <Link
                      className="text-decoration-none text-reset my-2 d-block"
                      key={item.link}
                      to={item.link}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>

            <div className="col-md-6 col-xl-2  mb-md-0 mb-4 text-end">
              <h5 className="text-uppercase fw-bold mb-3 text-black">
                معلومات الإتصال
              </h5>
              <p>
                <i className="fa-solid fa-location-dot fs-6 ms-2"></i>المدينة,
                الشارع, الحي
              </p>
              <p>
                <i className="fa-solid fa-phone fs-6 ms-2"></i>+90 222 333 4444
              </p>
              <p>
                <i className="fas fa-envelope fs-6 ms-2"></i>
                arrivo@email.com
              </p>
            </div>
            <div className="col-md-6 col-xl-3  mb-md-0 mb-4 text-end text-dark">
              <p>البريد الإلكتروني</p>

              <div>
                <input
                  type="text"
                  className="py-3 form-control fs-7"
                  aria-label="Sizing example input"
                  aria-describedby="inputGroup-sizing-lg"
                  placeholder="البريد الإلكتروني"
                />

                <button
                  type="button"
                  className="btn btn-lg d-block py-3 fs-7 mt-2 w-100 bg-secon text-white fs-6"
                >
                  إشترك معنا
                </button>
              </div>
              <Row className=" align-items-center m-1 mt-3">
                <span className="p-0 d-inline w-auto ms-3">تواصل معنا</span>
                <Link
                  to="/"
                  className="ms-3 text-reset border-dark border rounded-pill icon"
                >
                  <i className="fab fa-instagram "></i>
                </Link>
                <Link
                  to="/"
                  className="ms-3 text-reset border rounded-pill border-dark icon"
                >
                  <i className="fab fa-facebook-f "></i>
                </Link>
                <Link
                  to="/"
                  className="ms-3 text-reset border rounded-pill border-dark icon"
                >
                  <i className="fab fa-twitter"></i>
                </Link>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
