import Root from "./routs/Root";
import { BrowserRouter } from "react-router-dom";
import React, { Suspense } from "react";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { Container } from "react-bootstrap";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Container className="py-4" style={{height:'60vh'}}>
        <Suspense fallback={<div>loading...</div>}>
          <Root />
        </Suspense>
      </Container>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
