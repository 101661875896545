import React, { lazy } from "react";
import HomePage from "../../pages/HomePage";
const AboutUsPage = lazy(() => import("../../pages/AboutUsPage/index"));
const BlogsPage = lazy(() => import("../../pages/BlogsPage/index"));
const CommonQuestionsPage = lazy(() =>
  import("../../pages/CommonQuestionsPage/index")
);
const ContactUsPage = lazy(() => import("../../pages/ContactUsPage/index"));
const PrivacyPolicyPage = lazy(() =>
  import("../../pages/PrivacyPolicyPage/index")
);

const ServicesPage = lazy(() => import("../../pages/ServicesPage/index"));
const TourismProgrammesPage = lazy(() =>
  import("../../pages/TourismProgrammesPage/index")
);
const PublicRouts = [
  { id: `${Math.random()}`, path: "/", component: <HomePage /> },
  { id: `${Math.random()}`, path: "blogs", component: <BlogsPage /> },
  {
    id: `${Math.random()}`,
    path: "commonQuestions",
    component: <CommonQuestionsPage />,
  },
  { id: `${Math.random()}`, path: "aboutUs", component: <AboutUsPage /> },
  { id: `${Math.random()}`, path: "contactUs", component: <ContactUsPage /> },
  {
    id: `${Math.random()}`,
    path: "privacyPolicy",
    component: <PrivacyPolicyPage />,
  },
  { id: `${Math.random()}`, path: "services", component: <ServicesPage /> },
  {
    id: `${Math.random()}`,
    path: "tourismProgrammes",
    component: <TourismProgrammesPage />,
  },
];

export default PublicRouts;
