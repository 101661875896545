// import { Button } from "bootstrap";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useParams } from "react-router-dom";

let tags = [
  { name: "الرئيسية", link: "/" },
  { name: "الخدمات", link: "/services" },
  { name: "البرامج السياحية", link: "/tourismProgrammes" },
  { name: "من نحن ", link: "/aboutUs" },
  { name: "المدونة", link: "/blogs" },
  { name: "تواصل معنا", link: "/contactUs" },
];

function Header() {
  const { pathname } = useLocation();
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="white"
        className="border-bottom position-fixed top-0 left-0 right-0 w-100"
      >
        <Container>
          <Navbar.Brand className="m-0 font2" as={Link} to="/">
            <img
              width={150}
              src="https://i.ibb.co/KK3T71z/Screenshot-2.png"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto font1">
              {tags.map((item) => {
                return (
                  <Nav.Link
                    className="fw-bolder fs-7"
                    key={item.link}
                    style={pathname === item.link ? { color: "#ff850e" } : null}
                    active={item.link === pathname}
                    as={Link}
                    to={item.link}
                  >
                    {item.name}
                  </Nav.Link>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="py-5"></div>
    </>
  );
}

export default Header;
